import React from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ButtonOutlined from "../components/ButtonOutlined";
import SideBar from "../components/SideBar";

const SobreConstruirSozinho = () => {
  return (
    <Layout>
      <SEO
        type="website"
        title="Sobre Construir Sozinho"
        description="Sobre Construir Sozinho"
      />
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12">
          <div className="bg-gray-700 rounded-b-md mt-2 pb-4">
            <h1 className="text-center text-white text-4xl font-bold ">
              Sobre Construir Sozinho
            </h1>
            <div className="text-white px-8">
              <p className="text-white text-base pb-2 text-justify">
                Bem-vindo ao Construir Sozinho, este lugar foi criado com o
                objetivo de te ensinar e ajudar a resolver desde problemas do
                dia-a-dia até soluções complexas de engenharia mecânica,
                elétrica, civil e hidráulica.
              </p>
            </div>
          </div>
          <div className="mt-2 flex justify-between">
            <ButtonOutlined text="VOLTAR" to="/" />
          </div>
        </div>
        <div className="w-full lg:w-4/12 mt-2 lg:pl-2 text-white">
          <SideBar />
        </div>
      </div>
    </Layout>
  );
};
export default SobreConstruirSozinho;
